import { DEFAULT_PAGE_SIZE, OFFER_FILTER_TYPES } from "../../../common/utils"
import { filter$, search$ } from "../../../search/store/selectors"

import SearchFields from "../../../search/utils/search-fields"
import { createSelector } from "reselect"
import get from "lodash/get"
import pick from "lodash/pick"
import { searchableTextFromProperties } from "../../../search/utils"

const loadingActions = {
  LOAD_OFFERS: "LOAD_OFFERS",
}

const defaultPageInfo = {
  offset: 0,
  count: 0,
}

const entitiesState = state => state.entities
const offersState = state => state.offers
const pageInfoState = state => state.pageInfo
const loadingState = state => state.loading

const brandEntities$ = createSelector([entitiesState], state => state.brands)

export const offerEntities$ = createSelector(
  [entitiesState],
  state => state.offers
)

export const selectedOfferId$ = (state, props) => props.offerId

export const savedOfferIds$ = createSelector(
  [offersState],
  state => state.saved
)

export const isOfferSaved$ = createSelector(
  [selectedOfferId$, savedOfferIds$],
  (selectedOfferId, offerIds) => offerIds.includes(selectedOfferId)
)

const pageInfo$ = createSelector([pageInfoState], state => state.offers)

export const isLoadOffersLoading$ = createSelector([loadingState], state =>
  get(state, loadingActions.LOAD_OFFERS)
)

// filter the offer entities by the selected offer type
export const filteredOfferEntities$ = createSelector(
  [offerEntities$, offersState, filter$],
  (offers, offersState, filter) => {
    if (filter === OFFER_FILTER_TYPES.WALLET) {
      return pick(offers, offersState[OFFER_FILTER_TYPES.WALLET])
    } else if (filter === OFFER_FILTER_TYPES.SAVED) {
      return pick(offers, offersState[OFFER_FILTER_TYPES.SAVED])
    } else {
      return offers
    }
  }
)

export const recentOffers$ = createSelector([offerEntities$], offers =>
  Object.values(offers)
    .sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate))
    .slice(0, 4)
)

// filter the pageInfo by the selected offer type
export const filteredPageInfo$ = createSelector(
  [filter$, pageInfo$],
  (filter, pageInfo) => ({ ...defaultPageInfo, ...pageInfo[filter] })
)

// return an array of the filtered offer entities
export const filteredOffersArray$ = createSelector(
  [filteredOfferEntities$],
  offers => Object.values(offers) // sort here
)

// paginate the filtered offers array
export const paginatedFilteredOffersArray$ = createSelector(
  [filteredOffersArray$, filteredPageInfo$],
  (offers, pageInfo) => offers.slice(0, pageInfo.offset + DEFAULT_PAGE_SIZE)
)

// filter the offers for an offer type by the search term
export const searchResults$ = createSelector(
  [filteredOffersArray$, search$],
  (offers, search) => {
    const results = offers.filter(offer => {
      if (!search) return true
      return searchableTextFromProperties(SearchFields.OFFERS, offer)
        .toLowerCase()
        .includes(search.toLowerCase())
    })
    return results
  }
)

const offerEntitiesFromProps = (_, props) => get(props, "entities", [])

export const offersWithBrandImages$ = createSelector(
  [offerEntitiesFromProps, brandEntities$],
  (offers, brandEntities) =>
    offers.map(offer => {
      const brand = brandEntities[offer.program]
      const card_asset_image = get(brand, "card_asset_image.medium")
      return { ...offer, card_asset_image }
    })
)

// paginate the search results
export const paginatedSearchResults$ = createSelector(
  [searchResults$, filteredPageInfo$],
  (offers, pageInfo) => offers.slice(0, pageInfo.offset + DEFAULT_PAGE_SIZE)
)

export const searchResultsCount$ = createSelector(
  [searchResults$, filteredPageInfo$, search$],
  (offers, pageInfo, search) => (search === "" ? pageInfo.count : offers.length)
)

export const canLoadMore$ = createSelector(
  [filteredPageInfo$, searchResultsCount$],
  (pageInfo, searchResultCount) =>
    pageInfo.offset + DEFAULT_PAGE_SIZE < searchResultCount
)
