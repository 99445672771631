import React from "react"

export default ({ publishedDate }) => {
  return (
    <div className="flex flex-row w-full p-4">
      <div className="text-gray-500 flex-col w-full self-center text-left text-sm font-light">
        {!!publishedDate ? `Published on ${publishedDate}` : null}
      </div>
    </div>
  )
}
