import React, { useContext } from "react"
import { slugifyBrand, slugifyOffer } from "../../common/utils"

import AnimatedCard from "../../common/components/AnimatedCard"
import CardDetails from "../../common/components/CardDetails"
import { Link } from "gatsby"
import { OfferContext } from "../utils"
import OfferFooter from "./OfferFooter"
import ResponsiveCard from "../../common/components/ResponsiveCard"
import path from "path"

function Card({ item }) {
  const {
    hero_image,
    short_title,
    description,
    publish_date,
    program_name,
    card_asset_image,
    uuid,
  } = item
  const slug = slugifyOffer(item)
  const url = !!program_name
    ? path.join(
        "/",
        "brands",
        slugifyBrand({ name: program_name }),
        "offers",
        slug
      )
    : path.join("/", "offers", slug)

  const { showBrandImage } = useContext(OfferContext)

  return (
    <ResponsiveCard>
      <AnimatedCard className="flex max-w-sm shadow-lg bg-white m-2 w-full border border-gray-400">
        <Link to={url} className="flex flex-col w-full">
          <div
            className="bg-cover bg-center w-full bg-white relative"
            style={{
              backgroundImage: `url(${hero_image ? hero_image.medium : null})`,
              height: 300,
            }}
          >
            {showBrandImage && (
              <div className="w-1/3 border-2 border-white shadow bg-white m-auto left-0 right-0 bottom-0 absolute -mb-8">
                <img
                  src={card_asset_image}
                  className="w-full"
                  alt={program_name}
                />
              </div>
            )}
          </div>
          <div
            className={`flex flex-col flex-grow items-start justify-start ${
              showBrandImage ? "pt-8" : ""
            }`}
          >
            <CardDetails
              programName={program_name}
              title={short_title}
              description={description}
            />
            <OfferFooter publishedDate={publish_date} />
          </div>
        </Link>
      </AnimatedCard>
    </ResponsiveCard>
  )
}

export default Card
