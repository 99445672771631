import * as brandSelectors from "../store/selectors"

import BrandAnalytics, { EVENTS } from "../analytics"
import {
  createWalletItemStarted,
  removeWalletItemStarted,
} from "../../walletItems/store/actions/actionCreators"
import {
  useDisabledIntercomEffect,
  useTrackEventEffect,
} from "../../common/effects"

import { AnalyticsContext } from "../../common/utils"
import BrandDetails from "../components/BrandDetails"
import EntitiesContainer from "../../common/components/EntitiesContainer"
import Layout from "../../layout/components/Layout"
import { OfferContext } from "../../offers/utils"
import OfferList from "../../offers/components/OfferList"
import React from "react"
import SEO from "../../layout/components/SEO"
import { connect } from "react-redux"
import get from "lodash/get"
import { graphql } from "gatsby"
import { isUpdatingWalletItemLoading$ } from "../../walletItems/store/selectors/loadingSelectors"

const offerContextValue = {
  showBrandImage: false,
}

function BrandTemplate({
  brand,
  brandOffers,
  location,
  createWalletItemStarted,
  removeWalletItemStarted,
  submitting,
  walletItemId,
}) {
  const { uuid, name, card_asset_image, description } = brand
  const analytics = BrandAnalytics.brandProperties(name)
  const brandImage = get(card_asset_image, "medium", null)

  useTrackEventEffect(EVENTS.VIEW_BRAND, analytics)
  useDisabledIntercomEffect()

  const handleCreateWalletItem = () => {
    createWalletItemStarted(walletItemId)
  }

  const handleRemoveWalletItem = () => {
    removeWalletItemStarted(walletItemId)
  }

  return (
    <AnalyticsContext.Provider value={analytics}>
      <Layout>
        <SEO
          title={name}
          description={description}
          image={brandImage}
          url={location.href}
        />
        <EntitiesContainer>
          <div className="flex flex-col w-full mx-auto md:mt-24">
            <BrandDetails
              name={name}
              image={brandImage}
              title={name}
              uuid={uuid}
              description={description}
              createWalletItem={handleCreateWalletItem}
              removeWalletItem={handleRemoveWalletItem}
              submitting={submitting}
              walletItemId={walletItemId}
            />
          </div>
        </EntitiesContainer>
      </Layout>
    </AnalyticsContext.Provider>
  )
}

const mapStateToProps = (state, props) => ({
  brand: brandSelectors.internalBrandEntity$(state, props),
  brandOffers: brandSelectors.internalBrandOfferEntities$(state, props),
  submitting: isUpdatingWalletItemLoading$(state),
  walletItemId: brandSelectors.walletItemId$(state, props),
})

const mapDispatchToProps = {
  createWalletItemStarted,
  removeWalletItemStarted,
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandTemplate)

export const query = graphql`
  query brandQuery($id: String) {
    internalBrands(uuid: { eq: $id }) {
      id
      uuid
      name
      keywords
      most_popular
      card_asset_image {
        medium
      }
      description
    }
    allInternalOffers(
      filter: { program: { eq: $id } }
      sort: { fields: publish_date, order: DESC }
    ) {
      edges {
        node {
          uuid
          state
          short_title
          long_title
          description
          publish_date(formatString: "MMM DD, YYYY")
          categories {
            display_text
          }
          is_benefit
          program
          program_name
          hero_image {
            large
            medium
          }
        }
      }
    }
  }
`
