import BrandEvents from "./events"
import BrandProperties from "./properties"

export { BrandProperties as PROPERTIES, BrandEvents as EVENTS }

export default {
  brandProperties: name => ({
    [BrandProperties.BRAND_NAME]: name,
  }),
}
