import { AnalyticsContext, buildAppDeeplink } from "../../common/utils"
import { BrowserView, MobileView } from "react-device-detect"
import { EVENTS, PROPERTIES } from "../analytics"
import React, { useContext, useState } from "react"

import AppLink from "../../common/components/AppLink"
import CommonAnalytics from "../../common/analytics"

function BrandDetails({
  description,
  image,
  walletItemId,
  name,
  createWalletItem,
  removeWalletItem,
  submitting,
  title,
  uuid,
}) {
  const [clampDescription, setClampDescription] = useState(true)
  const analytics = useContext(AnalyticsContext)

  const onClick = () => {
    CommonAnalytics.trackEvent(EVENTS.BRAND_DESCRIPTION_CLICKED, analytics, {
      [PROPERTIES.BRAND_DESCRIPTION_ACTION]: clampDescription
        ? "opened"
        : "closed",
    })
    setClampDescription(!clampDescription)
  }

  const customLinkData = {
    programId: uuid,
    $og_title: name,
    $og_description: description,
    $og_image_url: image,
  }

  return (
    <div className="flex flex-col md:flex-row w-full justify-center md:justify-start mx-auto bg-white opacity-95 border-0 md:border">
      <div className="flex flex-row md:flex-col w-full md:w-1/4 items-start md:items-center justify-start relative">
        <BrowserView>
          <img
            alt={name}
            className="shadow shadow-lg w-full"
            src={image}
            style={{ backgroundSize: "cover" }}
          />
        </BrowserView>
        <MobileView>
          <AppLink url={buildAppDeeplink("program", customLinkData)}>
            <img
              alt={name}
              className="shadow shadow-lg w-full"
              src={image}
              style={{ backgroundSize: "cover" }}
            />
          </AppLink>
        </MobileView>
      </div>
      <div className="flex flex-col w-full md:w-1/2 item-center md:items-start align-start justify-start p-4">
        <div className="flex flex-row justify-between items-center w-full mb-8">
          <div className="text-2xl mt-0 text-left text-gray-600 font-medium leading-snug">
            {title}
          </div>
        </div>
        <div className="flex flex-row">
          <p
            className={`text-left hover:text-gray-900 hover:cursor-pointer font-light ${
              clampDescription ? "line-clamp" : ""
            }`}
            onClick={onClick}
          >
            {description}
          </p>
        </div>
      </div>
      {/* <div className="flex flex-row md:flex-col w-full md:w-1/4 items-start md:items-end justify-start p-4">
        {submitting ? (
          <div className="w-32 border p-2 bg-blue-500 font-light text-center bg-white focus:outline-none text-white rounded-full font-medium">
            <span>
              Wallet <FontAwesomeIcon icon={faCircleNotch} spin={true} />
            </span>
          </div>
        ) : (
          <button
            disabled={submitting}
            onClick={walletItemId ? removeWalletItem : createWalletItem}
            className="w-32 border p-2 bg-blue-500 font-light text-center bg-white focus:outline-none text-white rounded-full font-medium"
          >
            {walletItemId ? (
              <span>
                Wallet <FontAwesomeIcon icon={faCheck} />
              </span>
            ) : (
              <span>
                Wallet <FontAwesomeIcon icon={faPlus} />
              </span>
            )}
          </button>
        )}
      </div> */}
    </div>
  )
}

export default BrandDetails
