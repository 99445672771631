import * as selectors from "../store/selectors"

import Card from "./Card"
import React from "react"
import { StyledEntitiesList } from "../../common/components/styled/entities"
import { connect } from "react-redux"

function OfferList({ offers }) {
  return (
    <StyledEntitiesList>
      {offers
        .filter(item => item.id !== "dummy")
        .map(item => (
          <Card key={item.uuid} item={item} />
        ))}
    </StyledEntitiesList>
  )
}

OfferList.defaultProps = {
  entities: [],
  offers: [],
}

const mapStateToProps = (state, props) => ({
  offers: selectors.offersWithBrandImages$(state, props),
})

export default connect(mapStateToProps)(OfferList)
