import { createSelector } from "reselect"
import get from "lodash/get"

const loadingState = state => state.loading

const loadingActions = {
  LOAD_WALLET_ITEM: "LOAD_WALLET_ITEM",
  CREATE_WALLET_ITEM: "CREATE_WALLET_ITEM",
  REMOVE_WALLET_ITEM: "REMOVE_WALLET_ITEM",
}

export const isLoadWalletItemsLoading$ = createSelector([loadingState], state =>
  get(state, loadingActions.LOAD_WALLET_ITEM)
)

export const isCreateWalletItemLoading$ = createSelector(
  [loadingState],
  state => get(state, loadingActions.CREATE_WALLET_ITEM)
)

export const isRemoveWalletItemLoading$ = createSelector(
  [loadingState],
  state => get(state, loadingActions.REMOVE_WALLET_ITEM)
)

export const isUpdatingWalletItemLoading$ = createSelector(
  [loadingState],
  state =>
    get(state, loadingActions.CREATE_WALLET_ITEM) ||
    get(state, loadingActions.REMOVE_WALLET_ITEM)
)
