import CommonAnalytics, { EVENTS, PROPERTIES } from "../analytics/"
import React, { useContext } from "react"

import { AnalyticsContext } from "../utils"

function AppLink({ url, children }) {
  const analytics = useContext(AnalyticsContext)

  const onClick = () => {
    CommonAnalytics.trackEvent(EVENTS.APP_LINK_CLICKED, analytics, {
      [PROPERTIES.BRANCH_LINK]: url,
    })
  }

  return (
    <div className="relative overlay-container">
      <a href={url} onClick={onClick}>
        {children}
        <div
          className="overlay h-12 flex items-center justify-center font-medium"
          style={{ opacity: 1 }}
        >
          Tap to open in Whatsmine
        </div>
      </a>
    </div>
  )
}

AppLink.defaultProps = {
  url: "",
}

export default AppLink
